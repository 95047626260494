import React from "react";
import { Routes, Route } from "react-router-dom";



import MainPageLayout from "./pages/_layout";
import HomePage from "./pages/HomePage";
import SupportPage from "./pages/SupportPage";
import BlogPage from "./pages/BlogPage";
import PricingPage from "./pages/PricingPage";
import ContactUsPage from "./pages/ContactUsPage";
import TermsUsePage from "./pages/legal/TermsUsePage";
import PrivacyPolicyPage from "./pages/legal/PrivacyPolicyPage";
import CookiesPolicyPage from "./pages/legal/CookiesPolicyPage";
import FeaturesPage from "./pages/FeaturesPage";
import TempoPage from "./pages/TempoPage";

function App() {
  return (
      <Routes>
        <Route path="/" element={<MainPageLayout />}>
          <Route index element={<TempoPage />} />
          <Route path="home" element={<HomePage />} />
          <Route path="support" element={<SupportPage />} />
          <Route path="blog" element={<BlogPage />} />
          <Route path="pricing" element={<PricingPage />} />
          <Route path="contactus" element={<ContactUsPage />} />
          <Route path="blog" element={<BlogPage />} />
          <Route path="features" element={<FeaturesPage />} />

          <Route path="legal/terms" element={<TermsUsePage />} />
          <Route path="legal/privacy" element={<PrivacyPolicyPage />} />
          <Route path="legal/cookies" element={<CookiesPolicyPage />} />
        </Route>
      </Routes>
  );
}

export default App;
