
import GetLanguage from "src/utils/languages";

const languages = GetLanguage();

interface IAppMenu {
    title: string,
    href: string,
    icon?: string,
    subTitle?: string,
}
export const LstMainMenus:IAppMenu[] = [
    {
        title: languages.home,
        href: '/home',
        icon: 'fa fa-home',
        subTitle: '',
    },
    {
        title: languages.features,
        href: '/features',
        icon: 'fa fa-cubes',
        subTitle: '',
    },
    {
        title: languages.support,
        href: '/support',
        icon: 'fa fa-question',
        subTitle: '',
    },
    {
        title: languages.blog,
        href: '/blog',
        icon: 'fa fa-blog',
        subTitle: '',
    },
    {
        title: languages.contactUs,
        href: '/contactus',
        icon: 'fa fa-address-book',
        subTitle: '',
    },
]