import flagSouth from "../assets/images/SouthAfrica.39c0cfcb.png";
import flagRwanda from "../assets/images/Rwanda.940410a0.png";
import flagIndia from "../assets/images/India.f3db0562.png";
import flagUsa from "../assets/images/USA.a265e7b8.png";
import flagUae from "../assets/images/UAE.52ab55b0.png";
import flagFrance from "../assets/images/France.bebf231e.png";

interface ICountryFlag {
  country: "southafrica" | "rwanda" | "india" | "france" | "usa" | "uae";
}

export const CountryFlag = ({ country }: ICountryFlag) => {
  const ctryName =
    country === "france"
      ? "France"
      : country === "india"
      ? "India"
      : country === "rwanda"
      ? "Rwanda"
      : country === "southafrica"
      ? "South Africa"
      : country === "uae"
      ? "UAE"
      : country === "usa"
      ? "USA"
      : "South Africa";

  const ctryFlag =
    country === "france"
      ? flagFrance
      : country === "india"
      ? flagIndia
      : country === "rwanda"
      ? flagRwanda
      : country === "southafrica"
      ? flagSouth
      : country === "uae"
      ? flagUae
      : country === "usa"
      ? flagUsa
      : flagSouth;

  return (
    <div className="flex gap-3 items-center border-2 border-gray-300 py-3 px-3 rounded-md mb-4">
      <img
        alt="flag"
        loading="lazy"
        width="50"
        height="10"
        decoding="async"
        data-nimg="1"
        className="rounded-md border text-transparent"
        src={ctryFlag}
      />
      <p className="text-base md:text-lg font-bold">{ctryName}</p>
    </div>
  );
};
