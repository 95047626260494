import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const TempoPage = () => {
    const navigate = useNavigate();
    useEffect(() => {
        navigate('/home');
    }, [])

  return (
    <div className='h-screen flex justify-center items-center'>
        <i className='fa fa-spin fa-spinner fa-3x'></i>
    </div>
  )
}

export default TempoPage