import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import GetLanguage from "src/utils/languages";

const languages = GetLanguage();

const CookiesPolicyPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = `${languages.cookiePolicy} | Monalytics`;
  }, []);
  
  return (
    <div className="flex flex-col legal">
      <div className="bg-white/40">
        <div className="container mx-auto">
          <div className="flex flex-col w-full max-w-3xl mx-auto">
            <h3 className="py-2 text-2xl font-bold">
              {languages.cookiePolicy}
            </h3>
            <p className="py-2">{languages.lastUpdated}: [26th August, 2024]</p>
            <p className="py-3 leading-[1.555] text-[18px]">
              <strong>Monalytics Ltd</strong> ("Company", "we", "us", or "our")
              uses cookies and similar technologies on our website and document
              management application (collectively, the "Services"). By using
              our Services, you consent to the use of cookies in accordance with
              this Cookie Policy.
            </p>

            <h3 className="mt-2 py-1 text-xl font-bold">
              1. What are Cookies?
            </h3>

            <p className="py-3 leading-[1.555] text-[16px]">
              Cookies are small text files that are stored on your device when
              you visit a website or use an application. They serve various
              purposes, such as remembering your preferences, analyzing website
              usage, and providing personalized content.
            </p>

            <h3 className="mt-2 py-1 text-xl font-bold">
              2. Types of Cookies We Use
            </h3>

            <h4 className="py-1 text-lg font-bold">2.1 Essential Cookies</h4>
            <p className="py-3 leading-[1.555] text-[16px]">
              These cookies are necessary for the functioning of our Services
              and cannot be disabled. They enable core functionality such as
              user authentication and account management.
            </p>

            <h4 className="py-1 text-lg font-bold">2.2 Performance Cookies</h4>
            <p className="py-3 leading-[1.555] text-[16px]">
              These cookies allow us to analyze how users interact with our
              Services, including which pages are visited most often and how
              users move through the website or application. This information
              helps us improve the performance and usability of our Services.
            </p>

            <h4 className="py-1 text-lg font-bold">
              2.3 Functionality Cookies
            </h4>
            <p className="py-3 leading-[1.555] text-[16px]">
              These cookies enable us to remember your preferences and settings,
              such as language preferences and customization options. They
              enhance your user experience by providing personalized features.
            </p>

            <h4 className="py-1 text-lg font-bold">2.4 Third-Party Cookies</h4>
            <p className="py-3 leading-[1.555] text-[16px]">
              We may also use cookies from third-party service providers, such
              as analytics and advertising partners, to track and analyze user
              behavior, deliver targeted advertisements, and measure the
              effectiveness of advertising campaigns.
            </p>

            <h3 className="mt-2 py-1 text-xl font-bold">
              3. How We Use Cookies
            </h3>
            <p className="py-3 leading-[1.555] text-[16px]">
              We use cookies for the following purposes:
              <br />
              <br />
              <ul>
                <li>Authenticating users and maintaining user sessions</li>
                <li>Analyzing website usage and performance</li>
                <li>Personalizing content and advertisements</li>
                <li>Remembering user preferences and settings</li>
              </ul>
            </p>
            <h3 className="mt-2 py-1 text-xl font-bold">4. Managing Cookies</h3>

            <p className="py-3 leading-[1.555] text-[16px]">
              Most web browsers allow you to control cookies through their
              settings preferences. You can typically configure your browser to
              accept, reject, or delete cookies, as well as to notify you when a
              cookie is being sent. Please note that disabling cookies may
              affect the functionality of our Services.
            </p>
            <h3 className="mt-2 py-1 text-xl font-bold">
              5. Third-Party Cookies
            </h3>

            <p className="py-3 leading-[1.555] text-[16px]">
              Some third-party services used on our website and application may
              also use cookies. These cookies are governed by the respective
              privacy and cookie policies of the third-party providers. We do
              not have control over these cookies or the data collected by them.
            </p>
            <h3 className="mt-2 py-1 text-xl font-bold">
              6. Changes to this Cookie Policy
            </h3>

            <p className="py-3 leading-[1.555] text-[16px]">
              We reserve the right to update or change this Cookie Policy at any
              time. We will notify you of any changes by posting the updated
              policy on our website or through other appropriate communication
              channels. Your continued use of our Services after any such
              changes constitutes your acceptance of the revised Cookie Policy.
            </p>
            <h3 className="mt-2 py-1 text-xl font-bold">7. Contact Us</h3>

            <p className="py-3 leading-[1.555] text-[16px]">
              If you have any questions about this Cookie Policy or our use of
              cookies, please contact us&nbsp;
              <Link
                className="text-blue-500 hover:text-blue-800"
                to="/contactus"
              >
                here
              </Link>
              .
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookiesPolicyPage;
