import appDecl from "./declarations";

const getAppLang = (): string => {
  const elt = localStorage.getItem(appDecl.currentLang);
  return elt ?? "en";
};

const retLang = (
  enCont: string,
  frCont: string,
  rwCont: string = ""
): string => {
  const lang = getAppLang();

  return lang === "en"
    ? enCont
    : lang === "fr"
    ? frCont
    : lang === "rw"
    ? rwCont
    : enCont;
};

export default function GetLanguage() {
  return {
    campaigns: retLang("Campaigns", "Campagnes", ""),
    home: retLang("Home", "Acceuil", ""),
    features: retLang("Features", "Fonctionnalités", ""),
    contactUs: retLang("Contact Us", "Nous contacter", ""),
    signIn: retLang("Sign In", "Se connecter", ""),
    signUp: retLang("Sign Up", "S'inscrire", ""),
    growthBySoc: retLang(
      "Growth by social analytics",
      "Croissance grâce à l'analyse sociale",
      ""
    ),
    french_lng: retLang("French", "Français", ""),
    english_lng: retLang("English", "Anglais", ""),
    menu: retLang("Menu", "Menu", ""),
    support: retLang("Support", "Aide", ""),
    pricing: retLang("Pricing", "Prix", ""),
    blog: retLang("Blog", "Journal", ""),
    termsUse: retLang("Terms of use", "Conditions d'utilisation", ""),
    privPolicy: retLang("Privacy policy", "Politique de confidentialité", ""),
    cookiePolicy: retLang("Cookie policy", "Politique de cookies", ""),
    legal: retLang("Legal", "Juridique", ""),
    websites: retLang("Websites", "Sites Web", ""),
    footprintCarbon: retLang("Footprint carbon", "Empreinte carbone", ""),
    projectManagt: retLang("Project management", "Gestion des projets", ""),
    fillUpContact: retLang(
      "Fill up the form below to send us a message.",
      "Remplissez le formulaire ci-dessous pour nous envoyer un message.",
      ""
    ),
    blogMemo: retLang(
      "Step into the world of efficient document management with us. Explore tips, tricks, and industry insights to streamline your workflow and maximize productivity. We're excited to have you on board!",
      "Entrez avec nous dans le monde de la gestion efficace des documents. Découvrez des conseils, des astuces et des informations sur le secteur pour rationaliser votre flux de travail et maximiser la productivité. Nous sommes ravis de vous compter à bord!",
      ""
    ),
    blogMemoDet: retLang(
      "We are working hard to inform you about how you can benefit from Monalytics when managing your social network feeds. We will publish this information as soon as possible.",
      "Nous travaillons dur pour vous informer sur la manière dont vous pouvez bénéficier de Monalytics lors de la gestion de vos flux de réseaux sociaux. Nous publierons ces informations dès que possible.",
      ""
    ),
    contatctUsTxt: retLang(
      "You can contact us for any topic regarding Monalytics (Social Feeds Management). You want to place an order, you want to follow a training or understand certain features.",
      "Vous pouvez nous contacter pour tout sujet concernant Monalytics (Gestion des Flux Sociaux). Vous souhaitez passer une commande, suivre une formation ou comprendre certaines fonctionnalités.",
      ""
    ),

    typeMsg: retLang("Type your message here", "Tapez votre message ici", ""),
    subject: retLang("Subject", "Objet", ""),
    email: retLang("Email", "Email", ""),
    name: retLang("Name", "Nom", ""),
    send: retLang("Send", "Envoyer", ""),
    cancel: retLang("Cancel", "Annuler", ""),
    yes: retLang("Yes", "Oui", ""),
    getStarted: retLang("Get Started", "Démarrage", ""),
    waitServerResp: retLang(
      "Waiting for server response...",
      "Veuillez patienter la reponse du serveur...",
      ""
    ),
    messages: retLang("Messages", "Messages", ""),
    homeHarness: retLang(
      "Harness the Power of Social Media Insights, Forge Lucrative Influencer Partnerships, and Outsmart Your Competitors. Let us redefine success together!",
      "Exploitez la puissance des informations sur les réseaux sociaux, établissez des partenariats lucratifs avec des influenceurs et surclassez vos concurrents. Redéfinissons ensemble le succès !",
      ""
    ),

    reset: retLang("Reset", "Réinitialiser", ""),

    networkError: retLang("Network error", "Erreur de réseau", ""),
    networkErrorMsg: retLang(
      "There is a network error on this device. Check this issue and try again.",
      "Il y a une erreur réseau sur cet appareil. Vérifiez ce problème et réessayez.",
      ""
    ),
    fldRequired: retLang(
      "This field is required",
      "Ce champ est obligatoire",
      ""
    ),
    emailFormat: retLang(
      "The email format seems wrong.",
      "Le format de l'e-mail semble incorrect.",
      ""
    ),
    passLengthMsg: retLang(
      "Password must be at least 8 characters.",
      "Le mot de passe doit comporter au moins 8 caractères.",
      ""
    ),
    passValidMsg: retLang(
      "The password strength is too weak to be valid.",
      "Le niveau de sécurité du mot de passe est trop faible pour être valide.",
      ""
    ),
    sameAsPasswordMsg: retLang(
      "You are unable to confirm your password correctly.",
      "Vous n'arrivez pas à confirmer votre mot de passe correctement.",
      ""
    ),

    phonenbrLengthMsg: retLang(
      "The phone number must be 12 caracters long.",
      "Le numéro de téléphone doit être de 12 caractères.",
      ""
    ),
    comprDashboard: retLang(
      "Comprehensive Dashboard",
      "Tableau de bord complet",
      ""
    ),
    comprDashboardMSg: retLang(
      "Monalytics provides a centralized dashboard that gives you a holistic view of your performance across Facebook, LinkedIn, Instagram, and TikTok.",
      "Monalytics fournit un tableau de bord centralisé qui vous offre une vue globale de vos performances sur Facebook, LinkedIn, Instagram et TikTok.",
      ""
    ),
    advAnalytics: retLang("Advanced Analytics", "Analyse avancée", ""),
    advAnalyticsMsg: retLang(
      "Dive deep into your data with Monalytics' advanced analytics tools. Analyze trends, measure performance, and gain insights into your audience's behavior.",
      "Plongez au cœur de vos données grâce aux outils d'analyse avancés de Monalytics. Analysez les tendances, mesurez les performances et obtenez des informations sur le comportement de votre public.",
      ""
    ),
    socialListen: retLang("Social Listening", "Écoute sociale", ""),
    socialListenMsg: retLang(
      "Stay ahead of the competition with Monalytics' social listening feature. Monitor brand mentions, track keywords, and stay informed about industry trends.",
      "Gardez une longueur d'avance sur la concurrence grâce à la fonction d'écoute sociale de Monalytics. Surveillez les mentions de marque, suivez les mots-clés et restez informé des tendances du secteur.",
      ""
    ),
    matterBus: retLang(
      "No matter where your business sets its sights, connect with authentic voices that resonate with your target audience. Collaborate with Instagram influencers with bloggers, YouTubers, and more.",
      "Quelle que soit la cible de votre entreprise, connectez-vous à des voix authentiques qui trouvent un écho auprès de votre public cible. Collaborez avec des influenceurs Instagram comme des blogueurs, des YouTubeurs et bien plus encore.",
      ""
    ),

    noCredCard: retLang(
      "No credit card needed! Free 14 days trial",
      "Aucune carte de crédit nécessaire! Essai gratuit de 14 jours",
      ""
    ),

    trackAnal: retLang(
      "Track analytics thats guide your decision-making",
      "Suivez les analyses qui guident votre prise de décision",
      ""
    ),
    trackAnalMsg: retLang(
      "Track competitor analysis, customer trends, and industry shifts, all in one place.Get customized dashboards and reports to track what matters most to your business.",
      "Suivez l'analyse de la concurrence, les tendances des clients et les évolutions du secteur, le tout en un seul endroit. Obtenez des tableaux de bord et des rapports personnalisés pour suivre ce qui compte le plus pour votre entreprise.",
      ""
    ),
    globalPresence: retLang("Global Presence", "Présence mondiale", ""),
    lastUpdated: retLang("Last updated", "Dernière mise à jour", ""),
    knowledgeBase: retLang("Knowledge Base", "Base de connaissances", ""),
    phoneSupport: retLang("Phone Support", "Assistance par téléphone", ""),
    emailSupport: retLang("Email Support", "Assistance par e-mail", ""),
    developper: retLang("Developper", "Développeur", ""),
  };
}
