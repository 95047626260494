import { Link, useLocation } from "react-router-dom";
import logo from "../assets/images/logo_analytics.png";
import logoSimple from "../assets/images/logosimple.png";

import { ReactComponent as FrFlag } from "src/assets/images/svg/fr-flag.svg";
import { ReactComponent as UkFlag } from "src/assets/images/svg/uk-flag.svg";
import { ReactComponent as MenuRight } from "src/assets/images/svg/menuright.svg";

import GetLanguage from "src/utils/languages";
import appDecl from "src/utils/declarations";

import { LstMainMenus } from "src/utils/tempo_data";
import { useMemo } from "react";

const languages = GetLanguage();

export const HeadePageComp = () => {
  const location = useLocation();

  const pathElt = useMemo(() => {
    return location.pathname;
  }, [location]);

  return (
    <div className="2xl:max-w-[1440px] w-full mx-auto  relative px-8 sm:px-16 border-b">
      <nav className="flex justify-between items-center pt-5">
        <Link to="/">
          <img
            alt="logo"
            loading="lazy"
            width="200"
            height="65"
            decoding="async"
            data-nimg="1"
            style={{ color: "transparent" }}
            src={logo}
          />
        </Link>
        <ul className="hidden lg:flex md:gap-8 md:font-bold md:hover:cursor-pointer">
          {LstMainMenus.map((o, idx) => (
            <li key={idx}>
              <Link
                to={o.href}
                data-active={pathElt.includes(o.href)}
                className="data-[active=true]:text-blue-600 hover:text-slate-500"
              >
                {o.title}
              </Link>
            </li>
          ))}
        </ul>
        <div className="hidden lg:flex lg:items-center md:gap-8">
          <a
            rel="noreferrer"
            target="_blank"
            href={`${appDecl.webAppPath}/account/login`}
            className="hover:text-slate-500"
          >
            {languages.signIn}
          </a>
          <a
            rel="noreferrer"
            target="_blank"
            href={`${appDecl.webAppPath}/account/register`}
            className="bg-themeBlack px-6 py-2 rounded-3xl text-themeWhite  hover:bg-slate-700"
          >
            {languages.signUp}
          </a>
        </div>
        <div className="lg:hidden dropdown">
          <MenuRight
            className="text-xl dropdown-toggle h-6 w-6 cursor-pointer"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          />
          <ul className="dropdown-menu dropdown-menu-end">
            {LstMainMenus.map((o, idx) => (
              <li key={idx}>
                <Link
                  data-active={pathElt.includes(o.href)}
                  className="data-[active=true]:text-blue-600 dropdown-item flex items-center gap-x-2 py-2"
                  to={o.href}
                >
                  <i className={o.icon}></i> <span>{o.title}</span>
                </Link>
              </li>
            ))}
            <li>
              <hr className="dropdown-divider" />
            </li>
            <li className="hover:bg-neutral-300">
              <a
                rel="noreferrer"
                target="_blank"
                href={`${appDecl.webAppPath}/account/login`}
                className="flex items-center gap-x-2 py-2 px-3 hover:font-semibold"
              >
                <i className="fa fa-sign-in"></i>
                <span>{languages.signIn}</span>
              </a>
            </li>
            <li className="hover:bg-neutral-200">
              <a
                rel="noreferrer"
                target="_blank"
                href={`${appDecl.webAppPath}/account/register`}
                className="flex items-center gap-x-2 py-2 px-3 hover:font-semibold"
              >
                <i className="fa fa-key"></i>
                <span>{languages.signUp}</span>
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};

export const FooterPageComp = ({ onLang }: { onLang: (l: string) => void }) => {
  return (
    <div className="bg-[#f2f2f2]">
      <div className="container mx-auto">
        <footer className="p-10 bg-base-200 text-base-content flex flex-wrap">
          <aside className="pb-6 w-full md:w-1/4 flex flex-col mb-3 border-b md:mb-0 md:border-b-0">
            <div>
              <img alt="" src={logoSimple} className="h-[60px]" />
            </div>
            <p>
              <strong>Monalytics</strong>
              <br />
              {languages.growthBySoc}
            </p>
            <div className="flex items-center gap-x-3">
              <span
                onClick={() => onLang("en")}
                className="flex items-center gap-x-2 cursor-pointer text-blue-700 hover:text-blue-900"
              >
                <UkFlag className="h-4 w-6" />
                <span>{languages.english_lng}</span>
              </span>
              <span
                onClick={() => onLang("fr")}
                className="flex items-center gap-x-2 cursor-pointer text-blue-700 hover:text-blue-900"
              >
                <FrFlag className="h-4 w-6" />
                <span>{languages.french_lng}</span>
              </span>
            </div>
          </aside>
          <nav className="pb-6 w-1/2 md:w-1/4 flex flex-col space-y-3 mb-3 border-b md:mb-0 md:border-b-0">
            <div>
              <h6 className="font-semibold uppercase mb-2">{languages.menu}</h6>
            </div>
            <Link
              to="/support"
              className="text-neutral-600 hover:text-blue-600"
            >
              {languages.support}
            </Link>
            <Link
              to="/pricing"
              className="text-neutral-600 hover:text-blue-600"
            >
              {languages.pricing}
            </Link>
            <Link to="/blog" className="text-neutral-600 hover:text-blue-600">
              {languages.blog}
            </Link>
            <Link
              to="/contactus"
              className="text-neutral-600 hover:text-blue-600"
            >
              {languages.contactUs}
            </Link>
          </nav>
          <nav className="pb-6 w-1/2 md:w-1/4 flex flex-col space-y-3 mb-3 border-b md:mb-0 md:border-b-0">
            <div>
              <h6 className="font-semibold uppercase mb-2">
                {languages.legal}
              </h6>
            </div>
            <Link
              to="/legal/terms"
              className="text-neutral-600 hover:text-blue-600"
            >
              {languages.termsUse}
            </Link>
            <Link
              to="/legal/privacy"
              className="text-neutral-600 hover:text-blue-600"
            >
              {languages.privPolicy}
            </Link>
            <Link
              to="/legal/cookies"
              className="text-neutral-600 hover:text-blue-600"
            >
              {languages.cookiePolicy}
            </Link>
          </nav>
          <nav className="pb-6 w-1/2 md:w-1/4 flex flex-col space-y-3 mb-3 border-b md:mb-0 md:border-b-0">
            <div>
              <h6 className="font-semibold uppercase mb-2">
                {languages.websites}
              </h6>
            </div>

            <a
              href="https://footprintcarbon.com"
              className="text-neutral-600 hover:text-blue-600"
              target="_blank"
              rel="noreferrer"
            >
              {languages.footprintCarbon}
            </a>
            <a
              href="https://projectmanagt.com"
              className="text-neutral-600 hover:text-blue-600"
              target="_blank"
              rel="noreferrer"
            >
              {languages.projectManagt}
            </a>
          </nav>
        </footer>
      </div>
    </div>
  );
};
