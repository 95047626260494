import React, { useEffect } from "react";

import generalFxs from "src/utils/general_fx";
import getLang from "src/utils/languages";

import bg2 from "src/assets/images/pricing_page.jpg";

const appFxs = generalFxs();
const languages = getLang();

const loclang = appFxs.getLocalLanguage();

const PricingPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = `${languages.pricing} | Monalytics`;
  }, []);
  return (
    <div className="flex flex-col">
      <div
        style={{ backgroundImage: `url(${bg2})` }}
        className="relative  bg-no-repeat bg-cover"
      >
        <div className="absolute z-0 bg-gradient-to-r from-black w-full h-full"></div>
        <div className="container mx-auto py-12 relative z-[1]">
          <h3 className="py-3 text-[45px] text-white">{languages.features}</h3>
          <p
            data-lang={loclang}
            className="data-[lang='fr']:hidden w-full md:w-1/2 mt-4 text-xl text-white mb-4"
          >
              Check out our Pricing page to find the perfect plan for your business needs. 
              Monalytics offers flexible pricing options designed to suit any budget, with plans 
              ranging from basic analytics to advanced features for comprehensive social media 
              insights. No matter your requirements, we have a plan that provides the tools you 
              need to drive your social media strategy forward.
          </p>
          <p
            data-lang={loclang}
            className="data-[lang='en']:hidden w-full md:w-1/2 mt-4 text-xl text-white mb-4"
          >
              Consultez notre page Tarifs pour trouver le forfait idéal pour les besoins de votre 
              entreprise. Monalytics propose des options de tarification flexibles conçues pour 
              s'adapter à tous les budgets, avec des forfaits allant des analyses de base aux 
              fonctionnalités avancées pour des informations complètes sur les réseaux sociaux. 
              Quelles que soient vos exigences, nous avons un forfait qui vous fournit les outils 
              dont vous avez besoin pour faire avancer votre stratégie de médias sociaux.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PricingPage;
