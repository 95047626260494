import React, { useEffect } from "react";

import heroImage from "../assets/images/hero-image.48dc1e2c.png";
import imageWide1 from "../assets/images/image-1.99975d80.png";
import imageWide2 from "../assets/images/image-2.812b9bb5.png";

import socialListen from "../assets/images/social_listen.png";
import advAnalytics from "../assets/images/adv_analytics.png";
import comprDashboard from "../assets/images/comprehensive_dashboard.png";

import { CountryFlag } from "src/components/general_comps";

import GetLanguage from "src/utils/languages";
import generalFxs from "src/utils/general_fx";
import appDecl from "src/utils/declarations";

const languages = GetLanguage();
const appFxs = generalFxs();
const localLang = appFxs.getLocalLanguage();
const isEn = localLang === "en";

const HomePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = `${languages.home} | Monalytics`;
  }, []);
  return (
    <div className="container mx-auto">
      <div className="mt-16 flex flex-col items-center gap-8 py-8 px-4 max-w-screen-xl   text-start sm:text-center lg:py-16 lg:px-1">
        <div className="text-4xl font-black tracking-tight leading-none text-themeBlack md:text-5xl lg:text-6xl">
          {isEn && (
            <p className="text-center">We Help With Your Organizations</p>
          )}
          {isEn && <p className="text-center"> Growth</p>}

          {!isEn && (
            <p className="text-center">
              Nous vous aidons à atteindre une bonne
            </p>
          )}
          {!isEn && <p className="text-center">Croissance</p>}
        </div>
        <p className="text-center text-lg font-normal text-themeGrayText lg:text-xl sm:px-16 xl:px-48">
          {languages.homeHarness}
        </p>
        <a
          rel="noreferrer"
          target="_blank"
          href={`${appDecl.webAppPath}/account/login`}
          className="uppercase flex  justify-center items-center gap-3 bg-themeYellow w-max px-4 py-[0.4rem] medium:px-8 medium:py-[0.7rem] small:px-7  small:py-[0.6rem]  lg:px-7 lg:py-[0.8rem] rounded-[2rem] text-themeBlack font-bold"
        >
          {languages.getStarted}
          <span className="bg-themeWhite text-themeBlack p-3  rounded-full w-4 h-4 flex justify-center items-center">
            <i className="fa fa-arrow-right"></i>
          </span>
        </a>
        <img
          alt="hero-space"
          loading="lazy"
          src={heroImage}
          className="max-w-[950px] w-full"
        />
      </div>
      <div className="mt-8">
        <div
          className=" flex flex-col gap-20  bg-themeDarkWhiteBg py-8 px-4  lg:p-16 "
          id="features"
        >
          <div className="flex flex-col item-start gap-4 lg:items-center">
            <div className="flex flex-col items-center gap-2">
              <p className="font-bold text-3xl  sm:text-[2.8rem] text-center">
                {isEn
                  ? "Unleash the power of combined"
                  : "Libérez la puissance de la combinaison"}
              </p>
              <p></p>
              <p className="font-bold text-3xl sm:text-[2.8rem] text-center">
                {isEn ? "strategy and technology" : "stratégie et technologie"}
              </p>
            </div>
          </div>
          <div className="flex flex-col items-center lg:flex-row lg:justify-between">
            {/* 1 */}
            <div className="p-3 flex flex-col gap-y-2  items-center w-72">
              <div className="p-3 rounded-full bg-themeDarkShadow">
                <img alt="" src={comprDashboard} className="w-8 h-8" />
              </div>
              <p className="font-bold">{languages.comprDashboard}</p>
              <p className="text-themeGrayText text-center font-medium">
                {languages.comprDashboardMSg}
              </p>
            </div>

            {/* 2 */}
            <div className="p-3 flex flex-col gap-y-2  items-center w-72">
              <div className="p-3 rounded-full bg-themeDarkShadow">
                <img alt="" src={advAnalytics} className="w-8 h-8" />
              </div>
              <p className="font-bold">{languages.advAnalytics}</p>
              <p className="text-themeGrayText text-center font-medium">
                {languages.advAnalyticsMsg}
              </p>
            </div>

            {/* 3 */}
            <div className="p-3 flex flex-col gap-y-2  items-center w-72">
              <div className="p-3 rounded-full bg-themeDarkShadow">
                <img alt="" src={socialListen} className="w-8 h-8" />
              </div>
              <p className="font-bold">{languages.socialListen}</p>
              <p className="text-themeGrayText text-center font-medium">
                {languages.socialListenMsg}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-20 md:gap-28 py-8 px-4 lg:p-16 ">
        <div className="flex flex-col items-center lg:flex-row lg:items-center ">
          <img
            className="w-[30rem] lg:h-[30rem] lg:w-[48rem]"
            src={imageWide1}
            alt=""
          />
          <div className="pl-6 flex flex-col items-center gap-8 lg:items-start">
            <div className="flex flex-col  gap-3 text-3xl font-bold tracking-tight leading-10 text-themeBlack md:text-[2.3rem]">
              {isEn && (
                <>
                  <p>Strategically Connect with Audiences Across the Globe</p>
                </>
              )}
              {!isEn && (
                <>
                  <p>
                    Établissez des liens stratégiques avec le public du monde
                    entier
                  </p>
                </>
              )}
            </div>
            <p className="text-themeGrayText font-medium text-left lg:text-left">
              {languages.matterBus}
            </p>
            <div className="flex flex-col items-center lg:items-start">
              <a
                rel="noreferrer"
                target="_blank"
                href={`${appDecl.webAppPath}/account/login`}
                className="flex gap-3  items-center bg-themeYellow px-4 py-[0.7rem] medium:px-8 medium:py-[0.7rem] small:px-7  small:py-[0.6rem]  lg:px-7 lg:py-[0.8rem] rounded-[2rem] font-bold w-max"
              >
                {languages.getStarted}
                <span className="bg-themeWhite text-themeBlack p-3  rounded-full w-4 h-4 flex justify-center items-center">
                  <i className="fa fa-arrow-right"></i>
                </span>
              </a>
              <p className="text-themeGrayText font-medium mt-3">
                {languages.noCredCard}
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center gap-16  lg:flex-row lg:items-center">
          <div className="flex flex-col items-center gap-8 lg:items-start">
            <div className="w-full flex flex-col items-center gap-3 text-4xl font-bold tracking-tight leading-none text-themeBlack md:text-[2.8rem] lg:items-start">
              <p>{languages.trackAnal}</p>
            </div>
            <p className="text-themeGrayText font-medium text-left lg:text-start">
              {languages.trackAnalMsg}
            </p>
            <div className="flex flex-col gap-5 items-center lg:items-start">
              <a
                rel="noreferrer"
                target="_blank"
                href={`${appDecl.webAppPath}/account/login`}
                className="flex gap-3 items-center bg-themeYellow px-4 py-[0.7rem] medium:px-8 medium:py-[0.7rem] small:px-7  small:py-[0.6rem]  lg:px-7 lg:py-[0.8rem] rounded-[2rem] font-bold w-max"
              >
                {languages.getStarted}
                <span className="bg-themeWhite text-themeBlack p-3  rounded-full w-4 h-4 flex justify-center items-center">
                  <i className="fa fa-arrow-right"></i>
                </span>
              </a>
              <p className="text-themeGrayText font-medium text-center lg:text-start">
                {languages.noCredCard}
              </p>
            </div>
          </div>
          <img
            alt="neimage-o"
            loading="lazy"
            width="638"
            height="473"
            decoding="async"
            data-nimg="1"
            className="w-[30rem]  lg:h-[26rem] lg:w-[48rem]"
            src={imageWide2}
            style={{ color: "transparent" }}
          />
        </div>
      </div>
      <div className="py-8 px-4  lg:p-16 mb-5">
        <div className="flex flex-col space-y-4">
          <p className="font-bold text-lg  sm:text-[1.8rem]">
            {languages.globalPresence}
          </p>
          <div className="flex flex-wrap items-center gap-x-3">
            <CountryFlag country="southafrica" />
            <CountryFlag country="rwanda" />
            <CountryFlag country="india" />
            <CountryFlag country="france" />
            <CountryFlag country="usa" />
            <CountryFlag country="uae" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
