import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import generalFxs from "src/utils/general_fx";
import getLang from "src/utils/languages";

import bg2 from "src/assets/images/bg-support.jpg";
import { ReactComponent as SupportPhoneSvg } from "src/assets/images/svg/phone_support_color.svg";
import { ReactComponent as SupportEmailSvg } from "src/assets/images/svg/email_support_color.svg";
import { ReactComponent as KnowledgeEmailSvg } from "src/assets/images/svg/knowledge_support_color.svg";

const appFxs = generalFxs();
const languages = getLang();

const loclang = appFxs.getLocalLanguage();

const SupportPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = `${languages.support} | Monalytics`;
  }, []);

  return (
    <div className="flex flex-col">
      <div
        style={{ backgroundImage: `url(${bg2})` }}
        className="relative  bg-no-repeat bg-cover"
      >
        <div className="absolute z-0 bg-gradient-to-r from-black w-full h-full"></div>
        <div className="container mx-auto py-12 relative z-[1]">
          <h3 className="py-3 text-[45px] text-white">{languages.support}</h3>
          <p
            data-lang={loclang}
            className="data-[lang='fr']:hidden w-full md:w-1/2 mt-4 text-xl text-white mb-4"
          >
            Welcome to the support page for Monalytics! We're here to help you
            make the most of our platform and address any issues or questions
            you may have.
          </p>
          <p
            data-lang={loclang}
            className="data-[lang='en']:hidden w-full md:w-1/2 mt-4 text-xl text-white mb-4"
          >
            Bienvenue sur la page d'assistance de Monalytics! Nous sommes là pour
            vous aider à tirer le meilleur parti de notre plateforme et à
            répondre à tous les problèmes ou questions que vous pourriez avoir.
          </p>
        </div>
      </div>

      <div className="container mx-auto py-8">
        <div className="flex flex-wrap">
          <div className="w-full md:w-[48%] lg:w-[32%] mr-2">
            <div className="bg-white p-6 rounded-lg shadow-md">
              <SupportEmailSvg className="mx-auto mb-4 w-20 h-20" />
              <h2 className="text-xl font-semibold mb-2">
                {languages.emailSupport}
              </h2>
              <p
                data-lang={loclang}
                className="data-[lang='fr']:hidden text-gray-700"
              >
                For general inquiries, technical assistance, or any other
                support-related questions, please email us at&nbsp;
                <a
                  href="mailto:support@monalytics.app"
                  className="text-blue-500"
                >
                  support@monalytics.app
                </a>
                .
              </p>
              <p
                data-lang={loclang}
                className="data-[lang='en']:hidden text-gray-700"
              >
                Pour des demandes générales, une assistance technique ou tout
                autre questions liées à l'assistance, veuillez nous envoyer un
                e-mail&nbsp;
                <a
                  href="mailto:support@monalytics.app"
                  className="text-blue-500"
                >
                  support@monalytics.app
                </a>
                .
              </p>
            </div>
          </div>

          <div className="w-full md:w-[48%] lg:w-[32%] mr-2">
            <div className="bg-white p-6 rounded-lg shadow-md">
              <SupportPhoneSvg className="mx-auto mb-4 w-20 h-20" />
              <h2 className="text-xl font-semibold mb-2">
                {languages.phoneSupport}
              </h2>
              <p
                data-lang={loclang}
                className="data-[lang='fr']:hidden text-gray-700"
              >
                If you require immediate assistance or prefer to speak with a
                support representative over the phone, you can reach us at&nbsp;
                <a href="tel:250798600137" className="text-blue-500">
                  +250 798 600 137
                </a>
                .
              </p>
              <p
                data-lang={loclang}
                className="data-[lang='en']:hidden text-gray-700"
              >
                Si vous avez besoin d'une assistance immédiate ou si vous
                préférez parler à un représentant de l'assistance par téléphone,
                vous pouvez nous joindre au&nbsp;
                <a href="tel:250798600137" className="text-blue-500">
                  +250 798 600 137
                </a>
                .
              </p>
            </div>
          </div>

          <div className="w-full md:w-[48%] lg:w-[32%] mr-2">
            <div className="bg-white p-6 rounded-lg shadow-md">
              <KnowledgeEmailSvg className="mx-auto mb-4 w-20 h-20" />
              <h2 className="text-xl font-semibold mb-2">
                {languages.knowledgeBase}
              </h2>
              <p
                data-lang={loclang}
                className="data-[lang='fr']:hidden text-gray-700"
              >
                Explore our knowledge base for helpful articles, guides, and
                tutorials on using our Monalytics application.
              </p>
              <p
                data-lang={loclang}
                className="data-[lang='en']:hidden text-gray-700"
              >
                Explorez notre base de connaissances pour des articles utiles,
                des guides et des tutoriels sur l'utilisation de notre
                application Monalytics.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        data-lang={loclang}
        className="hidden data-[lang='en']:block container mx-auto py-8"
      >
        <div className="flex flex-col w-full max-w-[650px] mx-auto">
          <h2 className="text-xl py-2 font-bold">
            Frequently Asked Questions (FAQs)
          </h2>
          <h4 className="text-lg font-semibold mb-3">
            What is Monalytics, and how does it work?
          </h4>
          <p className="text-[16px] text-gray-700 mb-4">
            Monalytics is a web analytics platform designed to help businesses 
            gain insights from their social media data. We connect directly 
            to your social media accounts, including Facebook, LinkedIn, 
            Instagram, and TikTok, to gather data on engagement, audience growth, 
            content performance, and more. Our platform provides intuitive dashboards, 
            detailed reports, and analytics tools to help you understand your social 
            media performance and make data-driven decisions.
          </p>

          <h4 className="text-lg font-semibold mb-3">
            Is my data secure with Monalytics?
          </h4>
          <p className="text-[16px] text-gray-700 mb-4">
            Yes, we prioritize the security of your data. Monalytics employs robust 
            security measures, including data encryption, secure servers, and regular 
            security audits, to protect your information. We are fully compliant with 
            GDPR and other relevant data protection regulations, ensuring that your 
            data is handled with the utmost care and confidentiality.
          </p>

          <h4 className="text-lg font-semibold mb-3">
            Can I customize my reports and dashboards on Monalytics?
          </h4>
          <p className="text-[16px] text-gray-700 mb-4">
            Absolutely! Monalytics offers customizable dashboards and reports 
            to suit your unique business needs. You can personalize your dashboard 
            by selecting the metrics that matter most to you and arrange them using 
            our drag-and-drop interface. Additionally, our custom report builder 
            allows you to create detailed reports tailored to your specific 
            requirements and schedule them for regular delivery.
          </p>

          <h4 className="text-lg font-semibold mb-3">
            What kind of support does Monalytics offer?
          </h4>
          <p className="text-[16px] text-gray-700 mb-4">
            Monalytics offers comprehensive support to ensure you have a 
            smooth experience with our platform. Our dedicated support 
            team is available 24/7 to assist you with any questions or 
            issues you may have. We also provide access to a range of 
            resources, including tutorials, webinars, and best practice 
            guides, to help you get the most out of Monalytics. For more 
            personalized assistance, our account managers are always ready 
            to help you tailor our tools to your specific needs.
          </p>

          <h2 className="text-xl py-2 font-bold">Feedback</h2>
          <p className="text-[16px] text-gray-700 mb-4">
            We value your feedback and suggestions for improving our application. 
            If you have any ideas, feature requests, or comments, please submit your feedback through our&nbsp;
            <Link className="text-blue-600 hover:text-blue-900" to="/contactus">
              online form
            </Link>
            . Your input helps us enhance our platform and better serve our
            users.
          </p>
        </div>
      </div>

      <div
        data-lang={loclang}
        className="hidden data-[lang='fr']:block container mx-auto py-8"
      >
        <div className="flex flex-col w-full max-w-[650px] mx-auto">
          <h2 className="text-xl py-2 font-bold">
            Questions fréquemment posées (FAQs)
          </h2>
          <h4 className="text-lg font-semibold mb-3">
            Qu'est-ce que Monalytics et comment fonctionne-t-il ?
          </h4>
          <p className="text-[16px] text-gray-700 mb-4">
            Monalytics est une plateforme d'analyse Web conçue pour aider 
            les entreprises à tirer des enseignements de leurs données sur 
            les réseaux sociaux. Nous nous connectons directement à 
            vos comptes de réseaux sociaux, notamment Facebook, LinkedIn, 
            Instagram et TikTok, pour recueillir des données sur l'engagement, 
            la croissance de l'audience, les performances du contenu, etc. 
            Notre plateforme fournit des tableaux de bord intuitifs, 
            des rapports détaillés et des outils d'analyse pour vous aider à 
            comprendre vos performances sur les réseaux sociaux et à prendre 
            des décisions basées sur les données.
          </p>

          <h4 className="text-lg font-semibold mb-3">
            Mes données sont-elles sécurisées avec Monalytics ?
          </h4>
          <p className="text-[16px] text-gray-700 mb-4">
            Oui, nous accordons la priorité à la sécurité de vos données. 
            Monalytics utilise des mesures de sécurité robustes, notamment le 
            cryptage des données, des serveurs sécurisés et des audits de 
            sécurité réguliers, pour protéger vos informations. Nous sommes 
            entièrement conformes au RGPD et aux autres réglementations 
            pertinentes en matière de protection des données, garantissant 
            que vos données sont traitées avec le plus grand soin et la plus 
            grande confidentialité.
          </p>

          <h4 className="text-lg font-semibold mb-3">
            Puis-je personnaliser mes rapports et tableaux de bord dans Monalytics ?
          </h4>
          <p className="text-[16px] text-gray-700 mb-4">
            Absolument ! Monalytics propose des tableaux de bord et des rapports 
            personnalisables adaptés aux besoins spécifiques de votre entreprise. 
            Vous pouvez personnaliser votre tableau de bord en sélectionnant les 
            indicateurs qui vous intéressent le plus et en les organisant à l'aide 
            de notre interface glisser-déposer. De plus, notre générateur de 
            rapports personnalisés vous permet de créer des rapports détaillés 
            adaptés à vos besoins spécifiques et de les programmer pour une 
            livraison régulière.
          </p>

          <h4 className="text-lg font-semibold mb-3">
            What kind of support does Monalytics offer?
          </h4>
          <p className="text-[16px] text-gray-700 mb-4">
            Monalytics propose un support complet pour vous garantir 
            une expérience fluide avec notre plateforme. Notre équipe 
            d'assistance dédiée est disponible 24h/24 et 7j/7 pour vous 
            aider à répondre à toutes vos questions ou à résoudre vos 
            problèmes. Nous vous donnons également accès à une gamme de 
            ressources, notamment des tutoriels, des webinaires et des guides 
            de bonnes pratiques, pour vous aider à tirer le meilleur parti 
            de Monalytics. Pour une assistance plus personnalisée, nos 
            gestionnaires de compte sont toujours prêts à vous aider à 
            adapter nos outils à vos besoins spécifiques.
          </p>

          <h2 className="text-xl py-2 font-bold">Retour d'information</h2>
          <p className="text-[16px] text-gray-700 mb-4">
            Nous apprécions vos commentaires et suggestions pour améliorer notre
            application de gestion de documents. Si vous avez des idées, des
            demandes de fonctionnalités ou des commentaires, veuillez soumettre
            vos commentaires via
            <Link
              className="text-blue-600 hover:text-blue-900"
              to="/legal/privacy"
            >
              notre formulaire
            </Link>
            en ligne. Votre contribution nous aide à améliorer notre plateforme
            et à mieux servir nos utilisateurs.
          </p>
        </div>
      </div>
    </div>
  );
};

export default SupportPage;
