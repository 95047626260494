import React, { useEffect } from "react";

import { Link } from "react-router-dom";


import GetLanguage from "src/utils/languages";
import imgBlog from "src/assets/images/blog_page.jpg";


const languages = GetLanguage();

const BlogPage = () => {
  document.title = `${languages.blog} | Monalytics `;

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = `${languages.blog} | Monalytics`;
  }, []);

  return (
    <div>
      <div
        style={{
          backgroundImage: `url(${imgBlog})`,
          backgroundPosition: "center top",
          backgroundRepeat: "no-repeat",
        }}
        className="bg-cover"
      >
        <div className="relative">
          <div className="absolute z-0 bg-gradient-to-r from-black w-full h-full"></div>
          <div className="container mx-auto py-12 relative z-[1]">
            <h3 className="py-3 text-[45px] text-white">{languages.blog}</h3>
            <p className="w-full md:w-1/2 mt-4 text-xl text-white mb-4">
              {languages.blogMemo}
            </p>
          </div>
        </div>
        <div className="min-h-screen bg-gray-800 py-6 flex flex-col justify-center sm:py-12">
          <div className="relative py-3 w-full  max-w-[576px] sm:mx-auto">
            <div className="flex flex-col justify-center space-y-2 px-3">
              <h3 className="text-white text-3xl text-center mb-2">
                {languages.blogMemoDet}
              </h3>
              <Link to={'/home'} className="text-center inline-block px-4 py-2 border border-white rounded-[5px] bg-[#FFFFFF4D] text-white hover:bg-[#FFFFFF4D]/20 hover:text-white hover:border-white transition-all">{languages.home}</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogPage;
