import { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";

import imgContact from "src/assets/images/contact_page.jpg";
import { IContactUsPageMessage } from "src/models/general_types";

import GetLanguage from "src/utils/languages";
import GeneralFxs from "src/utils/general_fx";
import NetServices from "src/utils/netservices";
import { ArchFormInput } from "src/components/input_components";

const languages = GetLanguage();
const appFxs = GeneralFxs();

interface IFormError {
  fullName?: string;
  emailAddress?: string;
  subject?: string;
  messageContent?: string;
}
const defaultVal: IContactUsPageMessage = {
  emailAddress: "",
  fullName: "",
  messageContent: "",
  subject: "",
};

const ContactUsPage = () => {
  document.title = `${languages.contactUs} | Monalytics `;

  const [formRecord, setFormRecord] = useState<IContactUsPageMessage>({
    ...defaultVal,
  });
  const [formErrors, setformErrors] = useState<IFormError>({});
  const [sending, setSending] = useState<boolean>(false);
  const [messageSent, setMessageSent] = useState<boolean>(false);

  const navigate = useNavigate();

  const updateFormFld = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setFormRecord({ ...formRecord, [e.target.name]: e.target.value });
  };
  const resetForm = () => {
    setFormRecord({ ...defaultVal });
    setformErrors({});
  };
  const sendFormRecord = async () => {
    const errors: IFormError = {};
    if (!formRecord.fullName || formRecord.fullName.length === 0) {
      errors.fullName = languages.fldRequired;
    }
    if (!formRecord.emailAddress || formRecord.emailAddress.length === 0) {
      errors.emailAddress = languages.fldRequired;
    } else if (!appFxs.isEmailValid(formRecord.emailAddress)) {
      errors.emailAddress = languages.emailFormat;
    }
    if (!formRecord.subject || formRecord.subject.length === 0) {
      errors.subject = languages.fldRequired;
    }
    if (!formRecord.messageContent || formRecord.messageContent.length === 0) {
      errors.messageContent = languages.fldRequired;
    }

    setformErrors(errors);
    if (Object.keys(errors).length > 0) return;

    setSending(true);

    const oOper = await NetServices.requestPost(
      "general/sendcontactusmessage",
      {
        contactUsData: formRecord,
      }
    );

    if (!oOper.bReturn) {
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      return false;
    }

    setMessageSent(true);
    resetForm();
    setSending(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = `${languages.contactUs} | Monalytics`;
  }, []);

  return (
    <div className="flex flex-col">
      <div
        className="relative bg-center bg-no-repeat bg-cover"
        style={{ backgroundImage: `url(${imgContact})` }}
      >
        <div className="absolute z-0 bg-gradient-to-r from-white w-full h-full"></div>
        <div className="container mx-auto py-12 relative z-[1]">
          <h3 className="py-3 text-[45px] text-black">{languages.contactUs}</h3>
          <div className="w-full md:w-1/2 mt-4 text-xl text-black mb-4">
            {languages.contatctUsTxt}
          </div>
        </div>
      </div>
      <div className="min-h-screen bg-gray-800 py-6 flex flex-col justify-center sm:py-12">
        <div className="relative py-3 w-full  max-w-[576px] sm:mx-auto">
          <div className="absolute z-0 inset-0 bg-gradient-to-r from-indigo-700 to-purple-500 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"></div>
          <div className="text-white relative z-[1] px-4 py-10 bg-indigo-400 shadow-lg sm:rounded-3xl sm:p-20">
            <div className="text-center pb-6">
              <h1 className="text-3xl">{languages.contactUs}!</h1>
              <p className="text-gray-300">{languages.fillUpContact}</p>
            </div>

            <form>
                  {/* Name */}
                  <ArchFormInput
                    name="fullName"
                    type="text"
                    value={formRecord.fullName}
                    errorText={formErrors.fullName}
                    isBusy={sending}
                    placeholder={languages.name}
                    updateFormFld={updateFormFld}
                  />

                  {/* Email */}
                  <ArchFormInput
                    name="emailAddress"
                    type="email"
                    value={formRecord.emailAddress}
                    errorText={formErrors.emailAddress}
                    isBusy={sending}
                    placeholder={languages.email}
                    updateFormFld={updateFormFld}
                  />
                  {/* Subject */}
                  <ArchFormInput
                    name="subject"
                    type="text"
                    value={formRecord.subject}
                    errorText={formErrors.subject}
                    isBusy={sending}
                    placeholder={languages.subject}
                    updateFormFld={updateFormFld}
                  />

                  {/* message */}

                  <ArchFormInput
                    name="messageContent"
                    type="textarea"
                    value={formRecord.messageContent}
                    errorText={formErrors.messageContent}
                    isBusy={sending}
                    placeholder={languages.messages}
                    updateFormFld={updateFormFld}
                    rows={12}
                  />

                  <div className="flex justify-between">
                    <button
                      className="shadow bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                      type="button"
                      onClick={() => sendFormRecord()}
                      disabled={sending}
                    >
                      <span className="flex items-center gap-2">
                        {sending && <i className="fa fa-spin fa-spinner"></i>}
                        <span>{languages.send}</span>
                      </span>
                    </button>
                    <button
                      className="shadow bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                      type="button"
                      onClick={resetForm}
                      disabled={sending}
                    >
                      <span>{languages.reset}</span>
                    </button>
                  </div>
                </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUsPage;
