import React, { useEffect } from "react";

import generalFxs from "src/utils/general_fx";
import getLang from "src/utils/languages";

import bg2 from "src/assets/images/features_page.jpg";

const appFxs = generalFxs();
const languages = getLang();

const loclang = appFxs.getLocalLanguage();

const FeaturesPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = `${languages.features} | Monalytics`;
  }, []);
  return (
    <div className="flex flex-col">
      <div
        style={{ backgroundImage: `url(${bg2})` }}
        className="relative  bg-no-repeat bg-cover"
      >
        <div className="absolute z-0 bg-gradient-to-r from-black w-full h-full"></div>
        <div className="container mx-auto py-12 relative z-[1]">
          <h3 className="py-3 text-[45px] text-white">{languages.features}</h3>
          <p
            data-lang={loclang}
            className="data-[lang='fr']:hidden w-full md:w-1/2 mt-4 text-xl text-white mb-4"
          >
            Discover how Monalytics can transform your social media strategy on our Features page. 
            Explore a comprehensive suite of tools designed to provide in-depth analytics, custom reports, 
            social listening, campaign tracking, and more. With Monalytics, you’ll gain the insights you 
            need to optimize your performance across all major social media platforms, all while ensuring 
            your data is secure and your experience is seamless.
          </p>
          <p
            data-lang={loclang}
            className="data-[lang='en']:hidden w-full md:w-1/2 mt-4 text-xl text-white mb-4"
          >
            Découvrez comment Monalytics peut transformer votre stratégie de médias sociaux sur notre 
            page Fonctionnalités. Explorez une suite complète d'outils conçus pour fournir des analyses 
            approfondies, des rapports personnalisés, une écoute sociale, un suivi de campagne et bien 
            plus encore. Avec Monalytics, vous obtiendrez les informations dont vous avez besoin pour 
            optimiser vos performances sur toutes les principales plateformes de médias sociaux, tout 
            en garantissant la sécurité de vos données et une expérience fluide.
          </p>
        </div>
      </div>
      </div>
  )
}

export default FeaturesPage