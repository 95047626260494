/* eslint-disable @typescript-eslint/no-explicit-any */

import Swal from "sweetalert2";

import moment from "moment";
import jquery from "jquery";



import loadingAjax from "src/assets/images/loading.png";
import { HeaderType } from "src/models/general_types";

import AppDeclarations from "./declarations";
import GetLanguage from "./languages";
import appDecl from "./declarations";

function generalFx() {
  const language = GetLanguage();

  const getLocalStorageByKey = (key: string, defaultValue: string) => {
    const elt = localStorage.getItem(key);
    return elt ?? defaultValue;
  };
  const setLocalStorage = (key: string, valueData: string) => {
    localStorage.setItem(key, valueData);
  };
  const setLocalStorageByKey = (key: string, valueData: string) => {
    localStorage.setItem(key, valueData);
  };
  const cleanLocalStorageByKey = (key: string) => {
    localStorage.setItem(key, "");
  };
  const removeLocalStorageByKey = (key: string) => {
    localStorage.removeItem(key);
  };
  const setLocalLanguage = (lang: string) => {
    return setLocalStorageByKey(AppDeclarations.currentLang, lang);
  };

  const getLocalLanguage = () => {
    return getLocalStorageByKey(AppDeclarations.currentLang, "en");
  };

  const dateToUtcStr = (dDate: Date): string => {
    return new Date(dDate).toJSON();
  }

  return {
    getLocalLanguage,
    getLocalStorageByKey,
    setLocalStorage,
    setLocalStorageByKey,
    cleanLocalStorageByKey,
    removeLocalStorageByKey,
    setLocalLanguage,

    wiseStrings: (str?: string) => {
      return {
        isEmpty: !str || str.length === 0,
      };
    },
    isTelephoneNbrValid(telNbr: string): boolean {
      const phone = telNbr.replace(/[^0-9]/g, "");
      return phone.length === 12;
    },
    isEmailValid(email: string): boolean {
      const reg = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
      return reg.test(email);
    },
    getManagtDefaultHeaders(): HeaderType {
      const newDate = new Date();
      const accesskey = this.getLocalStorageByKey(
        appDecl.userAccessKey,
        ""
      );
      const lang = this.getLocalLanguage();
      const companyCode = appDecl.company_code;
      const operationDate = dateToUtcStr(new Date());

      let year_app = this.getLocalStorageByKey(
        appDecl.currentYearbook,
        newDate.getFullYear().toString()
      );
      const default_sms = this.getLocalStorageByKey(
        appDecl.currentDefaultSmsAcct,
        ""
      );
      const current_devise = this.getLocalStorageByKey(
        appDecl.currentDevise,
        "USD"
      );
      const current_branch = this.getLocalStorageByKey(
        appDecl.currentBranch,
        "HQ"
      );
      const user_email_value = this.getLocalStorageByKey(
        appDecl.userEmail,
        ""
      );
      const user_password_value = this.getLocalStorageByKey(
        appDecl.userPassword,
        ""
      );

      const oDate = new Date();
      const bInt = !Number.isNaN(year_app);
      if (!bInt) {
        year_app = oDate.getFullYear().toString();
      }

      return {
        "user-access-key": accesskey ? btoa(accesskey) : "",
        lang: lang ? btoa(lang) : "",
        "company-code": companyCode ? btoa(companyCode) : "",
        "client-operation-date": btoa(operationDate),
        "year-exercise-app": btoa(year_app),
        "default-sms-account": default_sms ? btoa(default_sms) : "",
        "current-devise-val": btoa(current_devise),
        "current-branch-val": btoa(current_branch),
        "user-email-value": user_email_value ? btoa(user_email_value) : "",
        "user-password-value": user_password_value
          ? btoa(user_password_value)
          : "",
        "app-type": btoa("managt"),
      };
    },
    //#region alerts dialogs
    showInputAlert(msgTitle: string, confirmButtonText: string = "OK") {
      return Swal.fire({
        title: msgTitle,
        input: "text",
        confirmButtonText,
        allowEscapeKey: false,
        allowOutsideClick: false,
      });
    },
    showAlert(
      msgTitle: string,
      msgBody: string,
      cancelButtonText: string = language.cancel
    ) {
      return Swal.fire({
        title: msgTitle,
        text: msgBody,
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonText,
        allowEscapeKey: false,
        allowOutsideClick: false,
      });
    },
    showConfirm(
      msgTitle: string,
      msgBody: string,
      cancelButtonText: string = language.cancel,
      confirmButtonText: string = language.yes
    ) {
      return Swal.fire({
        title: msgTitle,
        text: msgBody,
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText,
        confirmButtonText,
        allowEscapeKey: false,
        allowOutsideClick: false,
      });
    },
    showAjaxLoader(loadingMessage?: string) {
      const langText = loadingMessage ?? GetLanguage().waitServerResp;

      return Swal.fire({
        showConfirmButton: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        html: `
                    <div class="py-2 flex flex-col items-center justify-center">
                        <img class="animate-spin my-3" src="${loadingAjax}" width="50" height="50" />
                        <span class="py-3">${langText}</span>
                    </div>
                `,
      });
    },
    //#endregion
  };
}

export default generalFx;
