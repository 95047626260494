import React, { useEffect } from "react";

import GetLanguage from "src/utils/languages";

const languages = GetLanguage();

const TermsUsePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = `${languages.termsUse} | Monalytics`;
  }, []);
  return (
    <div className="flex flex-col legal">
      <div className="bg-white/40">
        <div className="container mx-auto">
          <div className="flex flex-col w-full max-w-3xl mx-auto">
            <h3 className="py-2 text-2xl font-bold">Terms of Use</h3>
            <p className="py-3 leading-[1.555] text-[18px]">
              Welcome to Monalytics! These Terms of Use ("Terms") govern your
              access to and use of our website, services, and applications
              (collectively, the "Services"). By accessing or using the
              Services, you agree to comply with and be bound by these Terms. If
              you do not agree with these Terms, you should not use our
              Services.
            </p>

            <h3 className="mt-2 py-1 text-xl font-bold">
              1. Acceptance of Terms
            </h3>
            <p className="py-3 leading-[1.555] text-[16px]">
              By using the Services, you affirm that you are at least 18 years
              old or have the consent of a parent or guardian, and that you are
              legally capable of entering into a binding agreement.
            </p>

            <h3 className="mt-2 py-1 text-xl font-bold">2. Changes to Terms</h3>
            <p className="py-3 leading-[1.555] text-[16px]">
              We reserve the right to modify these Terms at any time. Any
              changes will be effective immediately upon posting the updated
              Terms on our website. Your continued use of the Services after
              changes are posted constitutes your acceptance of the modified
              Terms.
            </p>

            <h3 className="mt-2 py-1 text-xl font-bold">3. Use of Services</h3>

            <h4 className="py-1 text-lg font-bold">3.1 Account Registration</h4>
            <p className="py-3 leading-[1.555] text-[16px]">
              To access certain features of the Services, you must create an
              account. You agree to provide accurate, current, and complete
              information during the registration process and to update such
              information to keep it accurate, current, and complete. You are
              responsible for safeguarding your account credentials and for all
              activities that occur under your account.
            </p>

            <h4 className="py-1 text-lg font-bold">
              3.2 Prohibited Activities
            </h4>
            <div className="py-3 leading-[1.555] text-[16px]">
              You agree not to: <br /> <br />
              <ul className="ml-4 pl-0">
                <li className="list-disc">
                  Use the Services for any illegal or unauthorized purpose.
                </li>
                <li className="list-disc">
                  Interfere with or disrupt the integrity or performance of the
                  Services or any third-party data contained therein.
                </li>
                <li className="list-disc">
                  Attempt to gain unauthorized access to the Services or related
                  systems or networks.
                </li>
                <li className="list-disc">
                  Use automated scripts or software to collect information or
                  interact with the Services.
                </li>
              </ul>
            </div>

            <h3 className="mt-2 py-1 text-xl font-bold">4. User Content</h3>

            <h4 className="py-1 text-lg font-bold">
              4.1 Responsibility for User Content
            </h4>
            <p className="py-3 leading-[1.555] text-[16px]">
              You are solely responsible for any content you submit, post, or
              display on or through the Services ("User Content"). You grant
              Monalytics a worldwide, non-exclusive, royalty-free,
              sublicensable, and transferable license to use, reproduce,
              distribute, prepare derivative works of, display, and perform the
              User Content in connection with the Services.
            </p>

            <h4 className="py-1 text-lg font-bold">4.2 Content Standards</h4>
            <div className="py-3 leading-[1.555] text-[16px]">
              User Content must not:
              <ul className="ml-4 pl-0">
                <li className="list-disc">
                  Contain any material that is defamatory, obscene, offensive,
                  hateful, or inflammatory.
                </li>
                <li className="list-disc">
                  Promote sexually explicit material, violence, or
                  discrimination based on race, sex, religion, nationality,
                  disability, sexual orientation, or age.
                </li>
                <li className="list-disc">
                  Infringe any copyright, trademark, trade secret, or other
                  intellectual property rights of any other person.
                </li>
              </ul>
            </div>

            <h3 className="mt-2 py-1 text-xl font-bold">5. Intellectual Property Rights</h3>
            <p className="py-3 leading-[1.555] text-[16px]">
              All content and materials available on the Services, including but not 
              limited to text, graphics, website name, code, images, and logos, 
              are the intellectual property of Monalytics and are protected by 
              applicable copyright, trademark, and other intellectual property 
              laws. Unauthorized use of any content or materials on the Services 
              is strictly prohibited.
            </p>

            <h3 className="mt-2 py-1 text-xl font-bold">6. Privacy Policys</h3>
            <p className="py-3 leading-[1.555] text-[16px]">
              Your use of the Services is also governed by our Privacy Policy, 
              which is incorporated into these Terms by this reference. 
              Please review our Privacy Policy to understand our practices 
              regarding the collection and use of your personal information.
            </p>

            <h3 className="mt-2 py-1 text-xl font-bold">7. Third-Party Services and Content</h3>
            <p className="py-3 leading-[1.555] text-[16px]">
              The Services may contain links to third-party websites, services, or 
              content that are not owned or controlled by Monalytics. We do not endorse 
              or assume any responsibility for any such third-party sites, services, or 
              content. If you access a third-party website from the Services, you do so 
              at your own risk.
            </p>

            <h3 className="mt-2 py-1 text-xl font-bold">8. Limitation of Liability</h3>
            <p className="py-3 leading-[1.555] text-[16px]">
              To the fullest extent permitted by law, Monalytics, its affiliates, 
              officers, directors, employees, agents, suppliers, or licensors 
              shall not be liable for any indirect, incidental, special, 
              consequential, or punitive damages, including but not limited 
              to loss of profits, data, use, goodwill, or other intangible 
              losses, resulting from (i) your use or inability to use the 
              Services; (ii) any unauthorized access to or use of our servers 
              and/or any personal information stored therein; (iii) any 
              interruption or cessation of transmission to or from the 
              Services.
            </p>

            <h3 className="mt-2 py-1 text-xl font-bold">9. Indemnification</h3>
            <p className="py-3 leading-[1.555] text-[16px]">
              You agree to indemnify, defend, and hold harmless Monalytics, 
              its affiliates, and their respective officers, directors, 
              employees, agents, and licensors from and against any claims, 
              liabilities, damages, losses,
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsUsePage;
