import React from "react";
import { Outlet } from "react-router-dom";
import { FooterPageComp, HeadePageComp } from "src/components/header_comp";
import GeneralFxs from "src/utils/general_fx";
import appDecl from "src/utils/declarations";


const appFxs = GeneralFxs();
const localLang = appFxs.getLocalLanguage();


export default function MainPageLayout() {
  const onChangeLang = (l: string) => {
    if(localLang === l) return;

    appFxs.setLocalStorageByKey(appDecl.currentLang, l);
    document.location.reload();
  }

  const onAction = (action: 'signin'|'signup') => {

  }

  return (
    <main>
      <HeadePageComp />
      <Outlet />
      <FooterPageComp onLang={onChangeLang} />
    </main>
  );
}
